<template>
    <div class="container-popover" :ref="`container-popover-${datepickerId}`">
      <div
        :id="`handler-${datepickerId}`"
        :data-popover-target="`my-popover-${datepickerId}`"
        class="handler"
      >
        <slot name="input"></slot>
      </div>
      <div :data-popover="`my-popover-${datepickerId}`" :id="`picker-${datepickerId}`" class="picker">
        <div class="date-picker-full">
          <Header
            :date="localDate"
            :monthNames="monthNames"
            :disabled="disabled"
            :showDates="showDates"
            @changeDate="changeDate"
          />
          <ContentMain
            :date="localDate"
            :pickerView="pickerView"
            :weekDays="weekDays"
            :monthNames="monthNames"
            :format="format"
            :showSeconds="showSeconds"
            :disabled="disabled"
            :blockDates="blockDates"
            :showDates="showDates"
            :showPicker="showPicker"
            :range="range"
            :rangeDate="rangeDate"
            @changeDate="changeDate"
            @changeTime="changeTime"
            @changeRange="changeRange"
          />
          <Footer
            :type="type"
            :pickerView="pickerView"
            :txtCancelButton="txtCancelButton"
            :txtSaveButton="txtSaveButton"
            :disabled="disabled"
            @handlePickerView="handlePickerView"
            @cancelPicker="cancelPicker"
            @savePicker="savePicker"
          />
        </div>
      </div>
    </div>
  </template>

  <script>
    import Header from './Header.vue';
    import ContentMain from './ContentMain.vue';
    import Footer from './Footer.vue';

    export default {
      components: { Header, ContentMain, Footer },
      props: {
        datepickerId: {
          type: String,
          default: ''
        },
        value: { default: new Date() },
        type: {
          type: String,
          default: 'date'
        },
        blockDates: {
          type: Function,
          default() {
            return false;
          }
        },
        showSeconds: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        format: { type: Number, default: 24 },
        variant: { type: String, default: 'button' },
        showDates: {
          type: Array,
          default() {
            return [];
          }
        },
        position: { type: String, default: 'left' },
        range: { type: Boolean, default: false },
        monthNames: {
          type: Array,
          default() {
            return [
              { name: 'Janeiro', short: 'Jan' },
              { name: 'Fevereiro', short: 'Fev' },
              { name: 'Março', short: 'Mar' },
              { name: 'Abril', short: 'Abr' },
              { name: 'Maio', short: 'Mai' },
              { name: 'Junho', short: 'Jun' },
              { name: 'Julho', short: 'Jul' },
              { name: 'Agosto', short: 'Ago' },
              { name: 'Setembro', short: 'Set' },
              { name: 'Outubro', short: 'Out' },
              { name: 'Novembro', short: 'Nov' },
              { name: 'Dezembro', short: 'Dez' }
            ];
          }
        },
        weekDays: {
          type: Array,
          default() {
            return [
              { name: 'Domingo', short: 'Dom' },
              { name: 'Segunda', short: 'Seg' },
              { name: 'Terça', short: 'Ter' },
              { name: 'Quarta', short: 'Qua' },
              { name: 'Quinta', short: 'Qui' },
              { name: 'Sexta', short: 'Sex' },
              { name: 'Sábado', short: 'Sáb' }
            ];
          }
        },
        txtCancelButton: { type: String, default: 'Cancelar' },
        txtSaveButton: { type: String, default: 'Salvar' },
        txtTime: { type: String, default: 'Hora' },
        txtDate: { type: String, default: 'Data' }
      },
      data() {
        return {
          date: null,
          localDate: null,
          pickerView: this.type == 'time' ? 'time' : 'date',
          showPicker: false,
          localRange: [],
          rangeDate: []
        };
      },
      methods: {
        changeRange(value) {
          this.localRange = value;
        },
        changeDate(value) {
          var changedDate = new Date(this.localDate);
          let totalDays = new Date(value.year, value.month + 1, 0).getDate();
          if (value.day > totalDays) {
            changedDate.setDate(totalDays);
          } else {
            changedDate.setDate(value.day);
          }

          changedDate.setMonth(value.month);

          changedDate.setFullYear(value.year);

          if (this.validateDate(changedDate) && !this.blockDates(changedDate)) {
            this.localDate = changedDate;
          } else {
            if (changedDate > this.showDates[1]) {
              this.localDate = this.showDates[1];
            } else if (changedDate < this.showDates[0]) {
              this.localDate = this.showDates[0];
            }
          }
        },
        changeTime(value) {
          var changedDate = new Date(this.localDate);

          changedDate.setHours(value.hours);

          changedDate.setMinutes(value.minutes);

          changedDate.setSeconds(value.seconds);

          this.localDate = changedDate;
        },
        validateDate(date) {
          if (this.showDates.length) {
            if (date >= this.showDates[0] && date <= this.showDates[1]) return true;
            return false;
          } else {
            return true;
          }
        },
        savePicker() {
          if (this.range) {
            this.rangeDate = this.localRange;
            this.$emit('input', this.rangeDate);
          } else {
            this.date = this.localDate;
            this.$emit('input', this.date);
          }
          this.showPicker = false;
          document.getElementById(`picker-${this.datepickerId}`).remove();

          this.handlePickerView('date');
          this.$emit('save');
        },
        cancelPicker() {
          this.showPicker = false;
          document.getElementById(`picker-${this.datepickerId}`).remove();

          this.handlePickerView('date');
          this.$emit('cancel');
        },
        handlePickerView(value) {
          this.pickerView = value;
        },
        initValues() {
          if (this.range) {
            this.localRange = this.value ?? [];
            this.rangeDate = this.value ?? [];
            this.date = this.value ? this.value[0] : new Date();
            this.localDate = this.value ? this.value[0] : new Date();
          } else {
            this.date = this.value ?? new Date();
            this.localDate = this.value ?? new Date();
          }
        }
      },
      watch: {
        showPicker() {
          this.initValues();
        }
      },
      created() {
        this.initValues();
      },
      mounted() {
        const vm = this;
        function isInViewport(element, pos) {
          const rect = element.getBoundingClientRect();
          const html = document.documentElement;

          if (pos.name == 'right') {
            if (rect.top < 0 && rect.right <= (window.innerWidth || html.clientWidth)) {
              element.style.top = '0px';
              return true;
            } else if (
              rect.bottom > window.innerHeight &&
              rect.right <= (window.innerWidth || html.clientWidth)
            ) {
              let top = parseFloat(element.style.top.replace('px', ''));
              let dif = rect.bottom - window.innerHeight;
              element.style.top = `${top - dif - 5}px`;
              return true;
            }
          }

          if (pos.name == 'left') {
            if (rect.top < 0 && rect.left <= (window.innerWidth || html.clientWidth)) {
              element.style.top = '0px';
              return true;
            } else if (
              rect.bottom > window.innerHeight &&
              rect.left <= (window.innerWidth || html.clientWidth)
            ) {
              let top = parseFloat(element.style.top.replace('px', ''));
              let dif = rect.bottom - window.innerHeight;
              element.style.top = `${top - dif - 5}px`;
              return true;
            }
          }

          return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
          );
        }

        class Popover {
          constructor(trigger, { position = 'top', className = `popover-${vm.datepickerId}` }) {
            this.trigger = trigger;
            this.position = position;
            this.className = className;
            this.orderedPositions = ['top', 'right', 'bottom', 'left'];

            this.popover = document.getElementById(`picker-${vm.datepickerId}`);

            Object.assign(this.popover.style, {
              position: 'fixed'
            });

            this.handleWindowEvent = () => {
              if (this.isVisible) {
                this.show();
              }
            };

            this.handleDocumentEvent = (evt) => {
              if (
                this.isVisible &&
                !this.trigger.contains(evt.target) &&
                !this.popover.contains(evt.target) &&
                evt.target.dataset.picker !== 'popover' &&
                evt.target.parentElement.dataset.picker !== 'popover'
              ) {
                this.popover.remove();
                vm.showPicker = false;
              }
            };
          }

          get isVisible() {
            return document.body.contains(document.getElementById(`picker-${vm.datepickerId}`));
          }

          show() {
            vm.showPicker = true;
            window.addEventListener('scroll', this.handleWindowEvent);
            window.addEventListener('resize', this.handleWindowEvent);

            document.body.appendChild(this.popover);

            const { top: triggerTop, left: triggerLeft } = this.trigger.getBoundingClientRect();
            const { offsetHeight: triggerHeight, offsetWidth: triggerWidth } = this.trigger;
            const { offsetHeight: popoverHeight, offsetWidth: popoverWidth } = this.popover;

            const positionIndex = this.orderedPositions.indexOf(this.position);

            const positions = {
              top: {
                name: 'top',
                top: triggerTop - popoverHeight,
                left: triggerLeft - (popoverWidth - triggerWidth) / 2
              },
              right: {
                name: 'right',
                top: triggerTop - (popoverHeight - triggerHeight) / 2,
                left: triggerLeft + triggerWidth
              },
              bottom: {
                name: 'bottom',
                top: triggerTop + triggerHeight,
                left: triggerLeft - (popoverWidth - triggerWidth) / 2
              },
              left: {
                name: 'left',
                top: triggerTop - (popoverHeight - triggerHeight) / 2,
                left: triggerLeft - popoverWidth
              }
            };

            const position = this.orderedPositions
              .slice(positionIndex)
              .concat(this.orderedPositions.slice(0, positionIndex))
              .map((pos) => positions[pos])
              .find((pos) => {
                this.popover.style.top = `${pos.top}px`;
                this.popover.style.left = `${pos.left}px`;
                return isInViewport(this.popover, pos);
              });

            this.orderedPositions.forEach((pos) => {
              this.popover.classList.remove(`${this.className}--${pos}`);
            });

            if (position) {
              this.popover.classList.add(`${this.className}--${position.name}`);
            } else {
              this.popover.style.top = positions.bottom.top;
              this.popover.style.left = positions.bottom.left;
              this.popover.classList.add(`${this.className}--bottom`);
            }
            document.addEventListener('click', this.handleDocumentEvent);
          }

          destroy() {
            vm.showPicker = false;
            this.popover.remove();

            document.removeEventListener('click', this.handleDocumentEvent);
            window.removeEventListener('scroll', this.handleWindowEvent);
            window.removeEventListener('resize', this.handleWindowEvent);
          }

          toggle() {
            if (!vm.disabled) {
              if (this.isVisible) {
                this.destroy();
              } else {
                this.show();
              }
            }
          }
        }

        const trigger = document.getElementById(`handler-${this.datepickerId}`);
        let popover = new Popover(trigger, { position: this.position });
        trigger.addEventListener('click', () => popover.toggle());
        document.getElementById(`picker-${this.datepickerId}`).remove();
      },
      destroyed() {
        document.getElementById(`picker-${this.datepickerId}`) &&
          document.getElementById(`picker-${this.datepickerId}`).remove();
      }
    };
  </script>

  <style lang="scss" scoped>
    .container-popover {
      position: relative;
    }

    .date-picker-full {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 400px;
      background: white;
      border: 1px solid #cfc4be;
      border-radius: 6px;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }

    .handler {
      height: 100%;
    }

    .picker {
      z-index: 99999 !important;
      margin: 5px;
    }

    @media (max-width: 1000px) {
      .date-picker-full {
        max-width: 100vw;
        max-height: 100vh;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        border-radius: 0;
        border: none;
        margin: 0;
      }
    }
  </style>
