<template>
    <div class="footer">
        <div>
            <button
                v-show="type == 'datetime'"
                type="button"
                class="footer-button toggle"
                @click="handlePickerView"
            >
                <CalendarIcon
                    class="btn-icon"
                    v-if="pickerView == 'time'"
                    data-picker="popover"
                />
                <ClockIcon class="btn-icon" v-else data-picker="popover" />
            </button>
        </div>
        <div class="footer-action">
            <button
                type="button"
                class="footer-button secondary"
                @click="cancelPicker"
            >
                {{ txtCancelButton }}
            </button>
            <button
                type="button"
                class="footer-button primary"
                :class="{ disabled }"
                @click="savePicker"
                :disabled="disabled"
            >
                {{ txtSaveButton }}
            </button>
        </div>
    </div>
</template>

<
<script>
import CalendarIcon from "@/assets/images/icons/calendar-icon.svg";
import ClockIcon from "@/assets/images/icons/clock-icon.svg";

export default {
    props: [
        "type",
        "pickerView",
        "txtCancelButton",
        "txtSaveButton",
        "disabled",
    ],
    components: { CalendarIcon, ClockIcon },
    methods: {
        savePicker() {
            this.$emit("savePicker");
        },
        cancelPicker() {
            this.$emit("cancelPicker");
        },
        handlePickerView() {
            this.$emit(
                "handlePickerView",
                this.pickerView == "date" ? "time" : "date"
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;
    z-index: 1001;
    background-color: #fff;

    width: 100%;
    height: 70px;

    border-top: 1px solid #cfc4be;
    .footer-action {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .footer-button {
        height: 38px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 6px 21px;
        background: transparent;
        border: none;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.175px;

        &.disabled {
            cursor: not-allowed;
        }

        &.toggle {
            padding: 12px;
        }

        &.primary {
            color: white;
            background: #974900;
            &:hover {
                background: #9f5714;
            }
            &:active {
                background: #a45f1f;
            }
        }

        &.secondary {
            border: 1px solid #974900;
            color: #974900;
        }

        &:hover {
            background: #ffede2;
        }
        &:active {
            background: #ffdbc4;
        }
    }
}

@media (max-width: 1000px) {
    .footer {
        height: 95px;
        padding: 29px;
        .footer-button {
            height: 63px;
            padding: 20px 35px;
            font-size: 20px;
            line-height: 23.48px;
            font-weight: 600;
            letter-spacing: 0.25px;

            &.toggle {
                padding: 16px;
                svg {
                    transform: scale(2.2);
                }
            }
        }
    }
}

@media (max-width: 1000px) and (max-height: 500px) {
    .footer {
        height: 60px;

        .footer-button{
            height: 42px;
        }
    }
}

@media (max-width: 1000px) and (max-height: 300px){
    .footer {
        padding: 8px 29px;
        .footer-button{
            height: 32px;
            padding: 15px;
            &.toggle {
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .footer {
        height: 70px;
        padding: 16px;
        .footer-button {
            height: 38px;
            padding: 6px 21px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.175px;
            &.toggle {
                padding: 12px;
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }
}
</style>
