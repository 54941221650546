<template>
    <div class="time-picker">
        <div class="time-picker-column">
            <div class="navigation">
                <button @click="panTop('hour')">
                    <CaretUp />
                </button>
            </div>
            <div class="picker-main" ref="hourScroll">
                <ul ref="hourList">
                    <li v-for="(value, index) in hours" :key="index" :data-hour="value"
                        :data-hour-formatted="formattedDigit(value)" :class="{
                            highlighted: index == selectedHour,
                            disabled
                        }" @click="changeHour(index)" :data-value="index">
                        {{ formattedDigit(value) }}
                        <span v-show="format == 12">{{ amPm(index) }}</span>
                    </li>
                </ul>
            </div>
            <div class="navigation">
                <button @click="panDown('hour')">
                    <CaretDown />
                </button>
            </div>
        </div>
        <div class="time-picker-column">
            <div class="navigation">
                <button @click="panTop('minute')">
                    <CaretUp />
                </button>
            </div>
            <div class="picker-main" ref="minuteScroll">
                <ul ref="minuteList">
                    <li v-for="(_, index) in 60" :key="index" :data-minute-formatted="formattedDigit(index)"
                        :data-minute="index" :class="{
                            highlighted: index == selectedMinute,
                            disabled
                        }" @click="changeMinute(index)" :data-value="index">
                        {{ formattedDigit(index) }}
                    </li>
                </ul>
            </div>
            <div class="navigation">
                <button @click="panDown('minute')">
                    <CaretDown />
                </button>
            </div>
        </div>
        <div class="time-picker-column" v-show="showSeconds">
            <div class="navigation">
                <button @click="panTop('second')">
                    <CaretUp />
                </button>
            </div>
            <div class="picker-main" ref="secondScroll">
                <ul ref="secondList">
                    <li v-for="(_, index) in 60" :key="index" :data-second-formatted="formattedDigit(index)"
                        :data-second="index" :class="{
                            highlighted: index == selectedSeconds,
                            disabled
                        }" @click="changeSeconds(index)" :data-value="index">
                        {{ formattedDigit(index) }}
                    </li>
                </ul>
            </div>
            <div class="navigation">
                <button @click="panDown('second')">
                    <CaretDown />
                </button>
            </div>
        </div>
        <div class="time-picker-active" v-if="showSeconds" ref="activeContainer">
            <span>:</span>
            <span>:</span>
        </div>
        <div class="time-picker-active" v-else ref="activeContainer">
            <span>:</span>
        </div>
    </div>
</template>
<script>
import CaretDown from '@/assets/images/icons/picker-caret-down.svg';
import CaretUp from '@/assets/images/icons/picker-caret-up.svg';

export default {
    props: ['date', 'showSeconds', 'format', 'disabled', 'showPicker'],
    components: { CaretDown, CaretUp },
    data() {
        return {
            selectedHour: null,
            selectedMinute: null,
            selectedSeconds: null,
            hours: [],
            idLastTimeout: null,
            scrollingTimeout: null,
            observer: null,
            device: window.innerWidth > 1000
                ? "desktop"
                : window.innerWidth > 480 && window.innerWidth <= 1000
                    ? "tablet"
                    : "mobile",
        };
    },
    methods: {
        initValues() {
            this.selectedHour = this.date.getHours();
            this.selectedMinute = this.date.getMinutes();
            this.selectedSeconds = this.date.getSeconds();
        },
        amPm(value) {
            return value >= 12 ? 'pm' : 'am';
        },
        changeTime() {
            this.$emit('changeTime', {
                hours: this.selectedHour,
                minutes: this.selectedMinute,
                seconds: this.selectedSeconds
            });
        },
        changeHour(hours) {
            this.selectedHour = hours;
            this.scrollActualHour(hours);
        },
        changeMinute(minutes) {
            this.selectedMinute = minutes;
            this.scrollActualMinute(minutes);
        },
        changeSeconds(seconds) {
            this.selectedSeconds = seconds;
            this.scrollActualSecond(seconds);
        },
        panTop(section) {
            if (section == 'hour') {
                const value = this.selectedHour - 1 == -1 ? 23 : this.selectedHour - 1
                this.selectedHour = value;
                this.scrollActualHour(value);

            } else if (section == 'minute') {
                const value = this.selectedMinute - 1 == -1 ? 59 : this.selectedMinute - 1;
                this.selectedMinute = value
                this.scrollActualMinute(value);

            } else {
                const value = this.selectedSeconds - 1 == -1 ? 59 : this.selectedSeconds - 1
                this.selectedSeconds = value;
                this.scrollActualSecond(value);
            }
        },
        panDown(section) {
            if (section == 'hour') {
                const value = this.selectedHour + 1 == 24 ? 0 : this.selectedHour + 1
                this.selectedHour = value;
                this.scrollActualHour(value);
            } else if (section == 'minute') {
                const value = this.selectedMinute + 1 == 60 ? 0 : this.selectedMinute + 1
                this.selectedMinute = value;
                this.scrollActualMinute(value);

            } else {
                const value = this.selectedSeconds + 1 == 60 ? 0 : this.selectedSeconds + 1
                this.selectedSeconds = value;
                this.scrollActualSecond(value);

            }
        },
        formattedDigit(value) {
            return String(value).padStart(2, '0');
        },
        scrollActualHour(hour) {
            const hourScroll = this.$refs.hourScroll;

            if (!hourScroll) return;

            let selectedHour = null;
            for (const item of this.$refs.hourList.querySelectorAll("li")) {
                if (item.dataset.hour == hour) {
                    selectedHour = item;
                }
            }

            let margin =
                window.innerWidth <= 480
                    ? (selectedHour?.offsetHeight + 48) * this.selectedHour
                    : window.innerWidth <= 1000
                        ? (selectedHour?.offsetHeight + 89) * this.selectedHour
                        : (selectedHour?.offsetHeight + 32) * this.selectedHour;

            if (selectedHour) {
                hourScroll.scrollTop = margin;
            }
        },
        scrollActualMinute(minute) {
            const minuteScroll = this.$refs.minuteScroll;

            if (!minuteScroll) return;

            let selectedMinute = null;
            for (const item of this.$refs.minuteList.querySelectorAll("li")) {
                if (item.dataset.minute == minute) {
                    selectedMinute = item;
                }
            }

            let margin =
                window.innerWidth <= 480
                    ? (selectedMinute?.offsetHeight + 48) * this.selectedMinute
                    : window.innerWidth <= 1000
                        ? (selectedMinute?.offsetHeight + 89) * this.selectedMinute
                        : (selectedMinute?.offsetHeight + 32) * this.selectedMinute;

            if (selectedMinute) {
                minuteScroll.scrollTop = margin;
            }
        },
        scrollActualSecond(second) {
            const secondScroll = this.$refs.secondScroll;

            if (!secondScroll) return;

            let selectedSecond = null;
            for (const item of this.$refs.secondList.querySelectorAll("li")) {
                if (item.dataset.second == second) {
                    selectedSecond = item;
                }
            }

            let margin =
                window.innerWidth <= 480
                    ? (selectedSecond?.offsetHeight + 48) * this.selectedSecond
                    : window.innerWidth <= 1000
                        ? (selectedSecond?.offsetHeight + 89) * this.selectedSecond
                        : (selectedSecond?.offsetHeight + 32) * this.selectedSecond;

            if (selectedSecond) {
                secondScroll.scrollTop = margin;
            }
        },
        setDraggableEvents(list, vm) {
            let mouseDown = false;
            var isDragged = false;
            let startX;
            let scrollTop;

            const startDragging = function (e) {
                list.classList.add('dragging');
                mouseDown = true;
                startX = e.pageY - list.offsetTop;
                scrollTop = list.scrollTop;
            };

            const stopDragging = function () {
                list.classList.remove('dragging');
                mouseDown = false;
            };

            const handleScroll = function () {
                clearTimeout(vm.scrollingTimeout);

                vm.scrollingTimeout = setTimeout(() => {
                    vm.scrollAllActual();
                }, 200);
            };

            list.addEventListener('touchstart', startDragging, false);
            list.addEventListener('touchend', stopDragging, false);
            list.addEventListener("scroll", handleScroll, false);

            if (this.device === "mobile") return;

            list.addEventListener('mouseup', () => {
                mouseDown = false;
                isDragged = false;

                list.classList.remove('active');
                setTimeout(() => {
                    vm.scrollAllActual();
                }, 200);
            });

            list.addEventListener('mousemove', (e) => {
                if (!mouseDown) return;
                isDragged = true;
                e.preventDefault();
                const x = e.pageY - list.offsetTop;
                const walk = (x - startX) * 2;
                list.scrollTop = scrollTop - walk;
            });
            list.addEventListener('mousedown', startDragging, false);
            list.addEventListener('mouseleave', stopDragging, false);

        },
        hourFormat() {
            var hourList = [];
            for (let index = 0; index < this.format; index++) {
                hourList.push(index);
            }

            if (this.format == 12) {
                hourList.push(12);
                for (let index = 1; index < this.format; index++) {
                    hourList.push(index);
                }
            }

            this.hours = hourList;
        },
        scrollAllActual() {
            this.scrollActualHour(this.selectedHour);
            this.scrollActualMinute(this.selectedMinute);
            this.scrollActualSecond(this.selectedSecond);
        },
        setIntersection(list, container, section) {
            this.$nextTick(() => {
                const pickerMain = document.querySelector(".picker-main");
                const options = {
                    root: container,
                    rootMargin: `-${(pickerMain.offsetHeight - pickerMain.querySelector("li").offsetHeight) / 2}px 0px`,
                    treshold: 0.25
                };

                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (this.selectedHour == null || this.selectedMinute == null || this.selectedSeconds == null) return;
                        if (entry.isIntersecting) {
                            if (section == 'hour') {
                                this.selectedHour = parseInt(entry.target.dataset.value);
                            } else if (section == 'minute') {
                                this.selectedMinute = parseInt(entry.target.dataset.value);
                            } else {
                                this.selectedSeconds = parseInt(entry.target.dataset.value);
                            }
                        }
                    });
                }, options);

                list.children.forEach((item) => {
                    observer.observe(item);
                });

                this.observer = observer;
            });
        },
        handleRotate() {
            const pickerMain = document.querySelector(".picker-main");
            this.observer.rootMargin = `-${(pickerMain.offsetHeight - pickerMain.querySelector("li").offsetHeight) / 2}px 0px`;
        }
    },
    mounted() {
        this.hourFormat();
        this.initValues();

        this.$nextTick(() => {
            this.scrollAllActual();
            setTimeout(() => {
                const vm = this;
                this.setDraggableEvents(this.$refs.hourScroll, vm);
                this.setDraggableEvents(this.$refs.minuteScroll, vm);
                this.setDraggableEvents(this.$refs.secondScroll, vm);

                this.setIntersection(this.$refs.hourList, this.$refs.hourScroll, 'hour');
                this.setIntersection(this.$refs.minuteList, this.$refs.minuteScroll, 'minute');
                this.setIntersection(this.$refs.secondList, this.$refs.secondScroll, 'second');
            }, 1000);
        })

        window.addEventListener('orientationchange', this.handleRotate)
    },
    destroyed() {
        window.removeEventListener("orientationchange", this.handleRotate)
    },
    watch: {
        format() {
            this.hourFormat();
        },
        selectedHour() {
            this.changeTime();
        },
        selectedMinute() {
            this.changeTime();
        },
        selectedSeconds() {
            this.changeTime();
        },
        showPicker() {
            this.initValues();
        },
    },
};
</script>
<style lang="scss" scoped>
.time-picker {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .time-picker-active {
        width: 398px;
        height: 42px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #4c4541;
        font-weight: 600;
        font-size: 16px;
        border-block: 1px solid #974900;
        background: transparent;
        pointer-events: none;
        position: absolute;
    }

    .time-picker-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-block: 8px;
        gap: 8px;

        .navigation {
            padding-block: 8px;

            button {
                background-color: #fff;
                border: none;
                border-radius: 6px;
                outline: none;

                &:hover {
                    background: #ffede2;
                }
            }
        }

        .picker-main {
            width: 100%;
            text-align: center;
            height: 10rem;
            overflow-y: scroll;
            padding-block: 57px;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 32px;

                li {
                    cursor: pointer;
                    color: #cfc4be;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8;
                    line-height: 26px;
                    font-size: 16px;
                    user-select: none;

                    &.highlighted {
                        font-weight: 600;
                        color: #4c4541;
                    }

                    &.disabled {
                        color: #cfc4be;
                        pointer-events: none;
                    }

                    span {
                        font-size: 12px;
                        line-height: 16px;
                        margin-left: 8px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .time-picker {
        height: calc(100% - 164px);

        .time-picker-active {
            width: 100%;
            height: 90px;
            font-size: 60px;
        }

        .time-picker-column {
            padding-block: 16px;
            gap: 31px;

            .picker-main {
                height: 22rem;
                padding-block: 130px;

                ul {
                    gap: 89px;

                    li {
                        font-size: 60px;
                        line-height: 50px;

                        span {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .navigation button {
                svg {
                    transform: scale(4);
                }

                overflow: hidden;
            }
        }
    }
}

@media (max-width: 1000px) and (max-height: 830px) {
    .time-picker {
        height: calc(100% - 164px);

        .time-picker-active {
            width: 100%;
            height: 90px;
            font-size: 60px;
        }

        .time-picker-column {
            padding-block: 0px;
            gap: 31px;

            .picker-main {
                height: 10rem;
                padding-block: 48px;

                ul {
                    gap: 89px;

                    li {
                        font-size: 60px;
                        line-height: 50px;

                        span {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .navigation button {
                svg {
                    transform: scale(4);
                }

                overflow: hidden;
            }
        }
    }
}

@media (max-width: 1000px) and (max-height: 635px) {
    .time-picker {
        height: calc(100% - 100px);

        .time-picker-active {
            height: 65px;
            font-size: 25px;
        }

        .time-picker-column {
            gap: 15px;

            .picker-main {
                height: 6rem;
                padding-block: 18px;

                ul {
                    li {
                        font-size: 40px;
                        line-height: 50px;

                        span {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) and (max-height: 405px) {
    .time-picker {
        height: calc(100% - 60px);

        .time-picker-active {
            height: 50px;
            font-size: 25px;
        }

        .time-picker-column {
            gap: 5px;

            .picker-main {
                height: 5rem;
                padding-block: 10px;

                ul {
                    li {
                        font-size: 28px;
                        line-height: 50px;

                        span {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .navigation button {
                svg {
                    transform: scale(2);
                }

                overflow: hidden;
            }
        }
    }
}

@media (max-width: 1000px) and (max-height: 330px) {
    .time-picker {
        height: calc(100% - 60px);

        .time-picker-active {
            height: 50px;
            font-size: 25px;
        }

        .time-picker-column {
            gap: 0px;

            .picker-main {
                height: 5rem;
                padding-block: 10px;

                ul {
                    li {
                        font-size: 28px;
                        line-height: 50px;

                        span {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .navigation {
                padding-block: 2px;
            }

            .navigation button {
                svg {
                    transform: scale(2);
                }

                overflow: hidden;
            }
        }
    }
}

@media (max-width: 480px) {
    .time-picker {
        height: calc(100% - 103px);

        .time-picker-active {
            width: 100%;
            height: 42px;
            font-size: 16px;
        }

        .time-picker-column {
            width: 100%;
            padding-block: 8px;
            gap: 8px;

            .picker-main {
                padding-block: 140px;
                height: 22rem;

                ul {
                    gap: 48px;

                    li {
                        font-size: 16px;
                        line-height: 26px;

                        span {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }

            .navigation button {
                svg {
                    transform: scale(1);
                }
            }
        }
    }
}

@media (max-width: 480px) and (max-height: 570px) {
    .time-picker {
        .time-picker-column {
            .picker-main {
                height: 16rem;
                padding-block: 100px;
            }
        }
    }
}
</style>
