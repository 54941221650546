<template>
    <div class="header">
      <div class="header-action">
        <button
          class="header-button"
          :class="{ open: monthListVisible, disabled }"
          type="button"
          @click="toggleMonthList"
          :disabled="disabled"
        >
          {{ monthNames[selectedMonth].name }} <CaretDown />
        </button>
        <button
          class="header-button"
          :class="{ open: yearListVisible, disabled }"
          type="button"
          @click="toggleYearList"
          :disabled="disabled"
        >
          {{ selectedYear }} <CaretDown />
        </button>
      </div>
      <div class="header-list" ref="monthDropdown" v-if="monthListVisible">
        <div class="header-list-section" v-for="year in years">
          <span class="sticky-year">{{ year }}</span>
          <div class="header-month-list">
            <ul>
              <li
                v-for="month in getMonths(year)"
                :key="month.value"
                :class="{
                  highlighted: month.value === selectedMonth && year === selectedYear
                }"
                @click="selectMonth(month.value, year)"
                data-picker="popover"
              >
                {{ month.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="header-list" ref="yearDropdown" v-if="yearListVisible">
        <ul>
          <li
            v-for="year in years"
            :key="year"
            :class="{
              highlighted: year === selectedYear
            }"
            @click="selectYear(year)"
            data-picker="popover"
          >
            {{ year }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  <script>
    import CaretDown from '@/assets/images/icons/calendar-caret-down.svg';

    export default {
      components: {
        CaretDown
      },
      props: ['date', 'monthNames', 'disabled', 'showDates', 'showDates'],
      data() {
        return {
          selectedMonth: this.date.getMonth(),
          selectedYear: this.date.getFullYear(),
          selectedDay: this.date.getDate(),
          monthListVisible: false,
          yearListVisible: false,
          years: [],
          months: []
        };
      },
      mounted() {
        this.generateYears();
        this.generateMonths();
      },
      methods: {
        generateYears() {
          this.selectedYear = this.date.getFullYear();
          let yearRange, firstYear, lastYear;
          if (this.showDates.length) {
            firstYear = this.showDates[0].getFullYear();
            lastYear = this.showDates[1].getFullYear();
            yearRange = lastYear - firstYear;
          } else {
            yearRange = 100;
            firstYear = this.selectedYear - Math.floor(yearRange / 2);
            lastYear = firstYear + yearRange - 1;
          }

          for (let year = firstYear; year <= lastYear; year++) {
            this.years.push(year);
          }
        },
        generateMonths() {
          this.selectedMonth = this.date.getMonth();

          for (let index = 0; index < this.monthNames.length; index++) {
            const month = this.monthNames[index];

            this.months.push({
              name: month.name,
              short: month.short,
              value: index
            });
          }
        },
        getMonths(year) {
          return this.months
            .map((month) => {
              return {
                ...month,
                year: year
              };
            })
            .filter((month) => {
              if (this.showDates.length) {
                var firstMonth = this.showDates[0].getMonth();
                var firstYear = this.showDates[0].getFullYear();
                var lastMonth = this.showDates[1].getMonth() + 1;
                var lastYear = this.showDates[1].getFullYear();

                if (year == firstYear && month.value >= firstMonth) {
                  return month;
                } else if (year == lastYear && month.value < lastMonth) {
                  return month;
                } else if (month.year > firstYear && month.year < lastYear) {
                  return month;
                }
              } else {
                return month;
              }
            });
        },
        selectMonth(month, year) {
          this.selectedMonth = month;
          this.selectedYear = year;
          this.monthListVisible = false;
          this.$emit('changeDate', { day: this.selectedDay, month, year });
        },
        selectYear(year) {
          this.selectedYear = year;
          this.yearListVisible = false;
          this.$emit('changeDate', {
            day: this.selectedDay,
            month: this.selectedMonth,
            year
          });
        },
        toggleMonthList() {
          if (this.yearListVisible) {
            this.yearListVisible = false;
          }

          this.monthListVisible = !this.monthListVisible;

          if (this.monthListVisible) {
            this.$nextTick(() => {
              const dropdown = this.$refs.monthDropdown;
              const selected = dropdown.querySelector('.highlighted');

              if (selected) {
                dropdown.scrollTop = selected.offsetTop - 100;
              }
            });
          }
        },
        toggleYearList() {
          if (this.monthListVisible) {
            this.monthListVisible = false;
          }
          this.yearListVisible = !this.yearListVisible;

          if (this.yearListVisible) {
            this.$nextTick(() => {
              const dropdown = this.$refs.yearDropdown;
              const selected = dropdown.querySelector('.highlighted');

              if (selected) {
                dropdown.scrollTop = selected.offsetTop - 100;
              }
            });
          }
        }
      },
      watch: {
        date(value) {
          this.selectedMonth = value.getMonth();
          this.selectedYear = value.getFullYear();
          this.selectedDay = value.getDate();
        }
      }
    };
  </script>
  <style lang="scss" scoped>
    .header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;

      .header-action {
        width: 100%;
        display: flex;

        .header-button {
          width: 100%;
          height: 58px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 16px;
          border: none;
          border-bottom: 1px solid #cfc4be;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #974900;
          background: #f1e8e0;

          &.disabled {
            color: #cfc4be;
            svg {
              fill: #cfc4be !important;
            }
          }

          &:first-child {
            border-right: 1px solid #cfc4be;
          }

          &:hover {
            background: #ffede2;
          }

          &.open {
            color: #fff;
            background: #974900;
            svg {
              fill: #fff !important;
            }
          }
        }
      }
      .header-list {
        width: 100%;
        height: 435px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 59px;
        overflow-y: scroll;
        background-color: #fff;
        z-index: 1000;
        text-align: center;
        padding-bottom: 68px;

        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 6px;
        }

        .header-list-section {
          width: 100%;
          display: flex;
          flex-direction: column;
          color: #4c4541;

          .sticky-year {
            width: 100%;
            padding-block: 12px;
            position: sticky;
            top: 0;
            padding-inline: 16px;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            border-bottom: 1px solid #cfc4be;
            background-color: #fff;
          }
          .header-month-list {
            display: flex;
            flex-direction: column;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            height: 50px;
            padding-block: 15px;
            border-bottom: 1px solid #cfc4be;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
            color: #998f8a;

            &.highlighted {
              color: #fff;
              background: #974900;
            }

            &:hover:not(.highlighted) {
              color: #974900;
              background: #ffede2;
            }
          }
        }
      }
    }

    @media (max-width: 1000px) {
      .header {
        .header-action .header-button {
          font-size: 26px;
          line-height: 36px;
          height: 84px;

          svg {
            transform: scale(1.5);
          }
        }
        .header-list {
          top: 84px;
          height: 100vh;
          padding-bottom: 153px;
          .header-list-section {
            .sticky-year {
              font-size: 26px;
            }
          }

          ul {
            li {
              padding-block: 60px;
              font-size: 22px;
              height: 150px;
            }
          }
        }
      }
    }

    @media (max-width: 1000px) and (max-height: 500px) {
      .header {
        .header-action .header-button {
          height: 40px;
        }

        .header-list {
          top: 40px;
          height: 100vh;

          ul {
            li {
              padding-block: 25px;
              font-size: 20px;
              height: 80px;
            }
          }
        }
      }
    }

    @media (max-width: 480px) {
      .header {
        .header-action .header-button {
          height: 36px;
          font-size: 14px;
          line-height: 20px;
          svg {
            transform: scale(1);
          }
        }
        .header-list {
          top: 36px;
          height: 100vh;
          padding-bottom: 130px;

          ul {
            li {
              padding-block: 15px;
              height: 50px;
              font-size: 14px;
            }
          }
        }
      }
    }
  </style>
