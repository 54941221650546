<template>
    <div class="content-main">
      <div class="calendar-view" v-if="pickerView == 'date'">
        <Calendar
          :date="date"
          :weekDays="weekDays"
          :range="range"
          :disabled="disabled"
          :blockDates="blockDates"
          :showDates="showDates"
          :showPicker="showPicker"
          :rangeDate="rangeDate"
          @changeDate="changeDate"
          @changeRange="changeRange"
        />
      </div>
      <div class="time-view" v-else>
        <HeaderDays
          :date="date"
          :weekDays="weekDays"
          :monthNames="monthNames"
          :disabled="disabled"
          :showPicker="showPicker"
          :blockDates="blockDates"
          :showDates="showDates"
          @changeDate="changeDate"
        />
        <TimePicker
          :date="date"
          :format="format"
          :showSeconds="showSeconds"
          :disabled="disabled"
          :showPicker="showPicker"
          @changeTime="changeTime"
        />
      </div>
    </div>
  </template>
  
  <script>
    import HeaderDays from './content-main/HeaderDays.vue';
    import Calendar from './content-main/Calendar.vue';
    import TimePicker from './content-main/TimePicker.vue';
  
    export default {
      components: {
        HeaderDays,
        Calendar,
        TimePicker
      },
      props: [
        'date',
        'pickerView',
        'showSeconds',
        'format',
        'range',
        'weekDays',
        'monthNames',
        'disabled',
        'blockDates',
        'showDates',
        'showPicker',
        'range',
        'rangeDate'
      ],
      methods: {
        changeDate(value) {
          this.$emit('changeDate', value);
        },
        changeTime(value) {
          this.$emit('changeTime', value);
        },
        changeRange(value) {
          this.$emit('changeRange', value);
        }
      }
    };
  </script>
  <style lang="scss">
    .content-main {
      display: flex;
      width: 100%;
      height: 100%;
      .time-view,
      .calendar-view {
        width: 100%;
      }
    }
  </style>
  